export const State = { NotConnected: "NotConnected", NotMinted: "NotMinted", Purchase: "Purchase", ViewTrees: "ViewTrees" };

export const getNextState = (account, token) => {
    if (account !== undefined && account !== null) {
        if (token <= 0) {
            return State.NotMinted;
        } else {
            return State.Purchase;
        }
    } else { 
        return State.NotConnected;
    }
}