import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import './PaginatedTrees.sass'

function Items({ currentItems }) {
    const { t } = useTranslation();
    return (
        <div className="table-wrap">
            <table>
                <thead>
                    <tr>
                        <th>№</th>
                        <th>{t("Tree ID")}</th>
                        <th>{t("Planting Year")}</th>
                        <th>{t("Field")}</th>
                        <th>{t("Latitude")}</th>
                        <th>{t("Longitude")}</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems?.length > 0 ? currentItems.map((tree, index) => {
                        return (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{tree.treeId}</td>
                                <td>{tree.plantingYear}</td>
                                <td>{tree.field}</td>
                                <td>{tree.latitude}</td>
                                <td>{tree.longitude}</td>
                            </tr>
                        );
                    }) : <tr></tr>}
                </tbody>
            </table>
        </div>
        
    );
}

function PaginatedTrees({ itemsPerPage, trees }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        // Fetch items from another resources.
        if (trees.length <= 0) {
            setCurrentItems([]);
            setPageCount(1);
            return;
        }
        
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(trees.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(trees.length / itemsPerPage));
    }, [trees, itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % trees.length;
        console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setItemOffset(newOffset);
    };

    return (
        <>
            <Items currentItems={currentItems} />
            <ReactPaginate
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="<"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
            />
        </>
    );
}

export default PaginatedTrees;