import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import './IntroSection.sass';
import { SessionContext } from "../../context";
import { connect } from "../../contracts/Web3Client";
import { getNextState, State } from "../../Navigation";



const IntroSection = () => {

    const { t } = useTranslation();

    async function ReadMore(e) {
        e.preventDefault();
        const introBtns = document.querySelectorAll('.intro .btn');
        introBtns.forEach((btn) => {
            btn.classList.toggle('active');
        });
        document.querySelector('.intro-max').classList.toggle('active');
    } 

    return (
        <div className="intro">
            <div className="intro-min intro">
                <div className="intro-min-content">
                    <p>{t("Dear Eco Investors, welcome to user account.")}</p>
                    <p>{t("To enter the account, registered users need to click on the Connect Wallet button in the upper right corner.")}</p>
                    <p>{t("In order to register and purchase olive trees, you need to follow a few simple steps:")}</p>
                    <ul>
                        <li>
                            {t("1. Install MetaMask wallet")} ({t("for")} <a href="https://apps.apple.com/ru/app/metamask-blockchain-wallet/id1438144202">IOS</a>, {t("for")} <a href="https://play.google.com/store/apps/details?id=io.metamask">Android</a>)
                        </li>
                        <li>
                            {t("2. Deposit the required amount of USDT to your wallet")}
                        </li>
                        <li>
                            {t("3. Purchase trees")}
                        </li>
                    </ul>
                </div>
                <a className="btn btn-more active" href="#" onClick={ReadMore}>{t("Open detailed step-by-step instructions")}</a>
            </div>
            <div className="intro-max intro">
                <p>{t("INSTRUCTIONS FOR BUYING OLIVES (ckeck the videos):")}</p>
                <p>{t("1. CREATING A METAMASK WALLET")} <a href="//www.youtube.com/watch?v=Iuvf8Vkjjjg" target="_blank">https://www.youtube.com/watch?v=Iuvf8Vkjjjg</a></p>
                <p>{t("2. ACCESS TO THE USER'S ACCOUNT")} <a href="//www.youtube.com/watch?v=nzIwU4nwqXE" target="_blank">https://www.youtube.com/watch?v=nzIwU4nwqXE</a></p>

                <ul>
                    <li>{t("Detailed step-by-step instructions:")}</li>
                    <li>{t("1. Install the MetaMask application, create your account.")}</li>
                    <li>{t("2. Switch MetaMask to the BNB Smart Chain network.")}</li>
                    <li>{t("3. Import the USDT token (it is needed to pay for the trees).")}</li>
                    <li>{t("4. We replenish BNB for $3-5 to pay the transaction fee and transfer USDT to pay for trees at the rate of the cost of 1 tree in 2022 - $147, 2023 - $118. When depositing BNB and USDT to your MetaMask wallet, use the Binance Smart Chain (BEP20) network.")}</li>
                    <li>{t("5. Go to the MetaMask wallet menu and select the browser tab, insert a link to activate your personal account there.")}</li>
                    <li>{t("6. Request a link to activate the account from your broker. If you don't have a broker, write to us at")} <a href="https://t.me/biodeposit_support_bot" target="_blank">{t("our support")}</a> {t("and we will provide you with an expert to accompany your transactions.")}</li>
                    <li>{t("7. After gaining access, you will be taken to the page for activating your personal account. Click the Connect Wallet button.")}</li>
                    <li>{t("8. You have a mint NFT button. Click on the button and pay for the mint NFT transaction.")}</li>
                    <li>{t("9. Next, click the Approve button at the bottom left and confirm the transaction.")}</li>
                    <li>{t("10. Specify the number of trees you want to buy and click the Buy button. Next, you need to confirm the transaction.")}</li>
                    <li>{t("11. The number of purchased trees appeared in your account. By clicking on the number of trees, you will see the unboxing of your NFT.")}</li>
                    <li>{t("There is all the data about the purchased trees: the number of trees, the cadastral number of the plantation, the geodata of each tree, the price and year of planting the tree.")}</li>
                    <li>{t("12. Now, when you log into your wallet, you will have access to your browser, and you can buy trees on your own at any time.")}</li>
                </ul>
                <p>{t("If you fail to complete these 12 steps, write to our")} <a href="https://t.me/biodeposit_support_bot" target="_blank">{t("support team")}</a> {t("and we will help you become an Eco investor.")}</p>
                <a className="btn btn-less" href="#" onClick={ReadMore}>{t("Collapse")}</a>
            </div>
        </div>
    );
}

export default IntroSection;