import { useTranslation } from "react-i18next";
import "./App.sass";
import ConnectWalletButton from "./components/connectWalletButton/ConnectWalletButton";
import LanguageButton from "./components/languageButton/LanguageButton";
import MintSection from "./components/mintSection/MintSection";
import PurchaseSection from "./components/purchaseSection/PurchaseSection";
import TreesDetails from "./components/treesDetails/TreesDetails";
import IntroSection from "./components/introSection/IntroSection";
import { useState, useEffect } from "react";
import { SessionContext } from "./context";
import Loader from "./components/loader/loader";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { State } from "./Navigation";
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import { defaultToken } from "./contracts/Web3Client";

function App() {

  // const { t } = useTranslation();
  const [selectedAccount, setAccount] = useState(undefined);
  const [viewState, setViewState] = useState(State.NotConnected);
  const [tokenId, setTokenId] = useState(defaultToken);
  const [isBusy, setIsBusy] = useState(false);
  const [filterProductId, setFilterProductId] = useState(0);

  const [provider, setProvider] = useState(undefined)

  async function onInitializeProviderClient() {
    const client = await EthereumProvider.init({
      projectId: '1168709263a6e1961b9a61b8a8ba54a2',
      showQrModal: true,
      qrModalOptions: { 
        themeMode: 'light',
        explorerRecommendedWalletIds: [
          '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
          'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
        ],
        explorerExcludedWalletIds: 'ALL'
     },
      chains: [56],
      methods: ['eth_sendTransaction', 'personal_sign'],
      events: ['chainChanged', 'accountsChanged'],
      
    })

    setProvider(client)
  }

  useEffect(() => {
    onInitializeProviderClient()
  }, [])

  return (
    <SessionContext.Provider value={{
      account: selectedAccount,
      setAccount: setAccount,
      userToken: tokenId,
      setUserToken: setTokenId,
      setIsBusy: setIsBusy,
      viewState: viewState,
      setViewState: setViewState,
      setFilterProductId: setFilterProductId
    }}>
      <div className={"App " + (viewState == State.NotConnected && "not-connected")}>
        <div className="App-header">
          <div className="content">
            <div className="logo">
              <a href="/">
                {viewState == State.NotConnected ? 
                (<img src="logo-white.svg" alt="logo" />) : 
                (<img src="logo.svg" alt="logo" />)}
              </a>
            </div>
            <LanguageButton />
            <div className="btns-wrap">
              <ConnectWalletButton provider={provider} />
            </div>
          </div>
        </div>

        <div className="App-content">
          <div className="content">

            {viewState === State.NotConnected && <IntroSection />}

            <MintSection />
            <PurchaseSection />
            <TreesDetails productId={filterProductId} />
          </div>
        </div>

        <Loader isVisible={isBusy} />
        <NotificationContainer />
      </div>
    </SessionContext.Provider>
  )
}

export default App