import { useTranslation } from "react-i18next";
import './LanguageButton.sass';

const LanguageButton = () => {
    const { i18n } = useTranslation();

    const langs = ['ru', 'en'];
    let currentLng = langs.indexOf(i18n.language);

    const listLangs = langs.map((lng, i) => {
      let isActive = i === currentLng;
      return ( 
      <button key={i} className={isActive ? "active" : "" } onClick={() => changeLanguage(lng)}>{lng}</button>
      )
    });

  
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    }

    return (
      <div className="lang-switcher">
        {listLangs}
      </div>
    )
}

export default LanguageButton;