import React, { useContext, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import './ConnectWalletButton.sass';
import { SessionContext } from "../../context";
import { connect } from "../../contracts/Web3Client";
import { getNextState, State } from "../../Navigation";

const ProviderMetamask = "metamask";
const ProviderTrust = "trustWallet";

const ConnectWalletButton = ({ provider }) => {

  const { t } = useTranslation();
  const { account, setAccount, setUserToken, setIsBusy, viewState, setViewState } = useContext(SessionContext);
  const [connectRequested, setConnectRequested] = useState(undefined);

  const hideConnectDropdown = useCallback((e) => {
    const isClosest = e.target.closest('.connect-wrap');
  
    if (!isClosest && document.querySelector('.connect-methods').classList.contains('active')) {
      document.querySelector('.connect-methods').classList.remove('active');
    }
  });

  useEffect(() => {
    document.addEventListener('click', hideConnectDropdown);
  }, []);

  useEffect(() => {
    if (provider) {
      provider.once('connect', onClientConnect);
    }
  }, [provider]);

  useEffect(() => {
    async function doConnect() {
      if (viewState === State.NotConnected && connectRequested != undefined) {
          let selectedProvider = undefined;
          switch (connectRequested) {
            case ProviderMetamask:
              selectedProvider = window.ethereum;
              break;
            case ProviderTrust:
              selectedProvider = provider;
              break;
          }

          const { account, token } = await connect(selectedProvider)
          setAccount(account);
          setUserToken(token);
          setViewState(getNextState(account, token));
      }
      setIsBusy(false);
      setConnectRequested(undefined);
    }
    doConnect();
  }, [connectRequested, viewState]);

  let onClientConnect = async () => {
    console.log("connected");
    setConnectRequested(ProviderTrust);
  }

  function getButtonText(account) {
    if (IsButtonActive()) {
      return t("Connect Wallet");
    }
    document.querySelector('.connect-methods').classList.remove('active');
    return account.slice(0, 6) + "..." + account.slice(-4);
  }

  function IsButtonActive() {
    return viewState === State.NotConnected;
  }

  async function MetamaskClick(providerName) {
    setIsBusy(true);
    setConnectRequested(ProviderMetamask);
  }

  async function ConnectTrigger() {
    document.querySelector('.connect-methods').classList.toggle('active')
  } 

  return (
    <div className="connect-wrap">
      <div className="connect-methods">
        <button className={"metamask btn"} onClick={MetamaskClick}>
          Metamask
          <img src="metamask.svg" alt="metamask" />
        </button>
        <button className={"connect-wallet btn"} onClick={() => provider.connect()}>
          Wallet connect
          <img src="wallet-connect.svg" alt="wallet-connect" /> 
        </button>
      </div>
      <button className={"btn-connect btn " + (!IsButtonActive() && "btn-disabled")} onClick={ConnectTrigger}>{t(getButtonText(account))}</button>
    </div>
  );
}

export default ConnectWalletButton;