import Web3 from 'web3/dist/web3.min.js';
import NFTContractBuild from './NFTContract.json';
import MainContractBuild from './MainContract.json';
import AgromineContractBuild from './AgromineContract.json';
import USDTContractBuild from './USDTContract.json';
import { NotificationManager } from "react-notifications";
import { bnbToWei } from "../common/utils";
import PQueue from "p-queue";

let web3 = undefined;
let Provider = undefined;
const BscNetworkId = 56;
let nftContract = undefined;
let usdtContract = undefined;
let mainContract = undefined;
let agromineContract = undefined;

const mintMinWei = bnbToWei(0.005);
const buyMinWei = bnbToWei(0.01);
export const defaultToken = -1;

const apiCallQueue = new PQueue({ concurrency: 1, intervalCap: 1, interval: 200 });

const makeApiCall = (promise) => {
    return apiCallQueue.add(promise);
}

const showError = (errorMessage) => {
    console.log(errorMessage);
    NotificationManager.error(errorMessage, 'Error', 10000);
}

const getNetworkId = async () => {
    const currentChainId = await web3.eth.net.getId()
    return currentChainId
};

export const connect = async (provider) => {
    let result = { account: undefined, token: -1 };

    if (typeof provider === 'undefined') {
        return result;
    }

    Provider = provider;
    web3 = new Web3(provider);

    if (await getNetworkId() !== BscNetworkId) {
        showError('Switch to BSC chain');
        return result;
    }

    try {
        let accounts = await makeApiCall(() =>
            provider.request({ method: 'eth_requestAccounts' })
        );
        result.account = accounts[0];
        // result.account = "0xD4E0EA6108315D62A7c08c7236bec70d0e47c6E9";
    }
    catch (err) {
        showError('Failed to get account');
        console.log(err);
        return result;
    }

    nftContract = new web3.eth.Contract(NFTContractBuild.abi, NFTContractBuild.smart_contract_address);
    usdtContract = new web3.eth.Contract(USDTContractBuild.abi, USDTContractBuild.smart_contract_address);
    mainContract = new web3.eth.Contract(MainContractBuild.abi, MainContractBuild.smart_contract_address);
    agromineContract = new web3.eth.Contract(AgromineContractBuild.abi, AgromineContractBuild.smart_contract_address);

    result.token = await tokenIDByUser(result.account);
    result.token = 35;

    return result;
};

export const approve = async (account) => {
    if (!account) {
        console.log("Not connected");
        return false;
    }
    try {
        return await usdtContract.methods
            .approve(MainContractBuild.smart_contract_address, "2000000000000000000000000")
            .send({ from: account });
    }
    catch (err) {
        showError('Failed to approve');
        console.log(err);
    }
    return false;
}

export const allowance = async (account) => {
    if (!account) {
        return false;
    }
    try {
        let allowanceValue = await usdtContract.methods
            .allowance(account, MainContractBuild.smart_contract_address).call();
        return allowanceValue > 30000000000000000000000.0;
    }
    catch (err) {
        showError('Failed to allowance');
        console.log(err);
    }
    return false;
}

const tokenIDByUser = async (account) => {
    if (!account) {
        console.log("Not connected");
    }

    try {
        let tokenId = await makeApiCall(() =>
            nftContract.methods.tokenIDByUser(account).call()
        );
        console.log(`Token received ${tokenId}`);
        return tokenId;
    }
    catch (err) {
        showError('Failed to get user token');
        console.log(err);
        return defaultToken;
    }
}

const getBnbBalance = async (account) => {
    let params = [account, "latest"];
    let strBnbBalance = await makeApiCall(() =>
        Provider.request({ method: 'eth_getBalance', params })
    );
    return BigInt(strBnbBalance);
}

export const mint = async (account, parentTokenId) => {
    try {
        let bnbBalance = await getBnbBalance(account);
        if (bnbBalance < mintMinWei) {
            showError('Not enough BNB');
            return defaultToken;
        }
        await makeApiCall(() =>
            agromineContract.methods.mint(parentTokenId).send({ from: account })
        );
        return await tokenIDByUser(account);
    }
    catch (err) {
        showError('Failed to mint');
        console.log(err);
    }
}

export const checkParentId = async (parentTokenId) => {
    try {
        if (parentTokenId === undefined ||
            parentTokenId == null ||
            parentTokenId == "" ||
            nftContract === undefined) return;
        return await makeApiCall(() =>
            nftContract.methods.tokenExists(parentTokenId).call()
        );
    }
    catch (err) {
        showError('Failed to check parent token id');
        console.log(err);
    }
}

export const buy = async (account, productId, itemsAmount, price, minAmount, maxAmount) => {
    if (itemsAmount < minAmount || maxAmount < itemsAmount) {
        showError(`Amount should be between ${minAmount} and ${maxAmount}`);
        return;
    }
    try {
        let bnbBalance = await getBnbBalance(account);
        if (bnbBalance < buyMinWei) {
            showError('Not enough BNB');
            return;
        }
        let response = await makeApiCall(() =>
            usdtContract.methods.balanceOf(account).call()
        );
        let usdtBalance = BigInt(response);
        if (price <= usdtBalance) {
            await makeApiCall(() =>
                mainContract.methods
                    .buyProduct(productId, itemsAmount)
                    .send({ from: account })
            );
        } else {
            showError(`Not enough USDT to buy ${itemsAmount} tree(s)`);
        }
    }
    catch (err) {
        showError('Failed to buy');
        console.log(err);
    }
}

export const getPrice = async (tokenId, productId, itemsAmount) => {
    try {
        let price = await makeApiCall(() =>
            mainContract.methods.getPrice(productId, itemsAmount, tokenId).call()
        );
        return BigInt(price[0]);
    }
    catch (err) {
        showError('Failed to get total price');
        console.log(err);
    }
}

export const getMinAmount = async (tokenId, productId) => {
    try {
        let minAmount = await makeApiCall(() =>
            mainContract.methods.GetMinBuyAmount(productId, tokenId).call()
        );
        return BigInt(minAmount);
    }
    catch (err) {
        showError('Failed to get min buy amount');
        console.log(err);
    }
}

export const getMaxAmount = async (productId) => {
    try {
        let maxAmount = await makeApiCall(() =>
            mainContract.methods.GetMaxBuyAmount(productId).call()
        );
        return BigInt(maxAmount);
    }
    catch (err) {
        showError('Failed to get max buy amount');
        console.log(err);
    }
}


export const getTreesId = async (tokenId, productId) => {
    try {
        return await makeApiCall(() =>
            mainContract.methods.getUserProductIDs(tokenId, productId).call()
        );
    }
    catch (err) {
        showError('Failed to get trees');
        console.log(err);
    }
}

export const getTreeInfo = async (treeId) => {
    try {
        let result = await makeApiCall(() =>
            mainContract.methods.getTreeInfo(treeId).call()
        );
        return {
            field: result["0"],
            plantingYear: result["1"],
            latitude: result["2"],
            longitude: result["3"],
            price: result["4"]
        };
    }
    catch (err) {
        if (err.message.search(".*Tree ID is out bounds.*") >= 0) {
            return null;
        } else {
            showError('Failed to get trees');
        }
        console.log(err);
    }
}

export const getUserDataById_1 = async (tokenId, productId) => {
    if (tokenId <= 0) {
        return null;
    }

    try {
        let result = await makeApiCall(() =>
            agromineContract.methods.getUserDataByID_1(tokenId, productId).call()
        );
        return {
            userTokenId: result["0"],
            parentTokenId: result["1"],
            level: result["2"],
            treesAmount: result["3"]
        };
    }
    catch (err) {
        showError('Failed to get user data');
        console.log(err);
    }
};

export const getUserDataById_2 = async (tokenId) => {
    if (tokenId <= 0) {
        return null;
    }

    try {
        let result = await makeApiCall(() =>
            agromineContract.methods.getUserDataByID_2(tokenId).call()
        );
        return {
            personalDeposit: BigInt(result["0"]),
            firstLineVolume: BigInt(result["1"]),
            secondLineVolume: BigInt(result["2"]),
            thirdLineVolume: BigInt(result["3"])
        };
    }
    catch (err) {
        showError('Failed to get user data');
        console.log(err);
    }
};

export const getUserDataById_3 = async (tokenId) => {
    if (tokenId <= 0) {
        return null;
    }

    try {
        let result = await makeApiCall(() =>
            agromineContract.methods.getUserDataByID_3(tokenId).call()
        );
        return {
            USDBalanceAccrued: result
        };
    }
    catch (err) {
        showError('Failed to get user data');
        console.log(err);
    }
};