import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    resources: {
      en: {
        translations: {
          "Connect Wallet": "Connect Wallet",
          "You should mint NFT to join BioDeposit Smart.": "You should mint NFT to join BioDeposit Smart.",
          "To mint NFT please use a link provided by your broker. If you don't have such link please reach our": "To mint NFT please use a link provided by your broker. If you don't have such link please reach our",
          "support.": "support.",
          "You referral link": "You referral link",
          "Copied": "Copied",
          "ID": "ID",
          "Parent ID": "Parent ID",
          "Level": "Level",
          "LB Level": "LB Level",
          "Trees Amount": "Trees Amount",
          "Deposit": "Deposit",
          "1st Line Volume": "1st Line Volume",
          "2nd Line Volume": "2nd Line Volume",
          "3rd Line Volume": "3rd Line Volume",
          "Structure Volume": "Structure Volume",
          "USDT Accrued": "USDT Accrued",
          "Withdraw": "Withdraw",
          "Buy Trees": "Buy Trees",
          "Amount to buy": "Amount to buy",
          "Approve": "Approve",
          "Buy": "Buy",
          "TestStr": "0x1234...7890",
          "Tree ID": "Tree ID",
          "Planting Year": "Planting Year",
          "Field": "Field",
          "Latitude": "Latitude",
          "Longitude": "Longitude",
          "Buy Price": "Buy Price",
          "Back": "Back",
          "Total": "Total",
          "Processing...": "Processing...",
          "Price": "Price",
          "Fall 2022": "Fall 2022",
          "Spring 2023": "Spring 2023",
          "Dear Eco Investors, welcome to user account.": "Dear Eco Investors, welcome to user account.",
          "To enter the account, registered users need to click on the Connect Wallet button in the upper right corner.": "To enter the account, registered users need to click on the Connect Wallet button in the upper right corner.",
          "In order to register and purchase olive trees, you need to follow a few simple steps:": "In order to register and purchase olive trees, you need to follow a few simple steps:",
          "1. Install MetaMask wallet": "1. Install MetaMask wallet",
          "for": "for",
          "2. Deposit the required amount of USDT to your wallet": "2. Deposit the required amount of USDT to your wallet",
          "3. Purchase trees": "3. Purchase trees",
          "Open detailed step-by-step instructions": "Open detailed step-by-step instructions",
          "Collapse": "Collapse",
          "INSTRUCTIONS FOR BUYING OLIVES (ckeck the videos):": "INSTRUCTIONS FOR BUYING OLIVES (ckeck the videos):",
          "1. CREATING A METAMASK WALLET": "1. CREATING A METAMASK WALLET",
          "2. ACCESS TO THE USER'S ACCOUNT": "2. ACCESS TO THE USER'S ACCOUNT",
          "Detailed step-by-step instructions:": "Detailed step-by-step instructions:",
          "1. Install the MetaMask application, create your account.": "1. Install the MetaMask application, create your account.",
          "2. Switch MetaMask to the BNB Smart Chain network.": "2. Switch MetaMask to the BNB Smart Chain network.",
          "3. Import the USDT token (it is needed to pay for the trees).": "3. Import the USDT token (it is needed to pay for the trees).",
          "4. We replenish BNB for $3-5 to pay the transaction fee and transfer USDT to pay for trees at the rate of the cost of 1 tree in 2022 - $147, 2023 - $118. When depositing BNB and USDT to your MetaMask wallet, use the Binance Smart Chain (BEP20) network.": "4. We replenish BNB for $3-5 to pay the transaction fee and transfer USDT to pay for trees at the rate of the cost of 1 tree in 2022 - $147, 2023 - $118. When depositing BNB and USDT to your MetaMask wallet, use the Binance Smart Chain (BEP20) network.",
          "5. Go to the MetaMask wallet menu and select the browser tab, insert a link to activate your personal account there.": "5. Go to the MetaMask wallet menu and select the browser tab, insert a link to activate your personal account there.",
          "6. Request a link to activate the account from your broker. If you don't have a broker, write to us at": "6. Request a link to activate the account from your broker. If you don't have a broker, write to us at",
          "our support": "our support",
          "and we will provide you with an expert to accompany your transactions.": "and we will provide you with an expert to accompany your transactions.",
          "7. After gaining access, you will be taken to the page for activating your personal account. Click the Connect Wallet button.": "7. After gaining access, you will be taken to the page for activating your personal account. Click the Connect Wallet button.",
          "8. You have a mint NFT button. Click on the button and pay for the mint NFT transaction.": "8. You have a mint NFT button. Click on the button and pay for the mint NFT transaction.",
          "9. Next, click the Approve button at the bottom left and confirm the transaction.": "9. Next, click the Approve button at the bottom left and confirm the transaction.",
          "10. Specify the number of trees you want to buy and click the Buy button. Next, you need to confirm the transaction.": "10. Specify the number of trees you want to buy and click the Buy button. Next, you need to confirm the transaction.",
          "11. The number of purchased trees appeared in your account. By clicking on the number of trees, you will see the unboxing of your NFT.": "11. The number of purchased trees appeared in your account. By clicking on the number of trees, you will see the unboxing of your NFT.",
          "There is all the data about the purchased trees: the number of trees, the cadastral number of the plantation, the geodata of each tree, the price and year of planting the tree.": "There is all the data about the purchased trees: the number of trees, the cadastral number of the plantation, the geodata of each tree, the price and year of planting the tree.",
          "12. Now, when you log into your wallet, you will have access to your browser, and you can buy trees on your own at any time.": "12. Now, when you log into your wallet, you will have access to your browser, and you can buy trees on your own at any time.",
          "If you fail to complete these 12 steps, write to our": "If you fail to complete these 12 steps, write to our",
          "support team": "support team",
          "and we will help you become an Eco investor.": "and we will help you become an Eco investor."
        }
      },
      ru: {
        translations: {
          "Connect Wallet": "Подключить Кошелек",
          "You should mint NFT to join BioDeposit Smart.": "Для того чтобы зарегистрироваться в проекте BioDeposit вам нужно сминтить NFT.",
          "To mint NFT please use a link provided by your broker. If you don't have such link please reach our": "Для минта NFT используйте ссылку, которую дал Вам Ваш брокер. Если у вас нет такой ссылки напишите пожалуйста нам в",
          "support.": "службу заботы.",
          "You referral link": "Ваша реферальная ссылка",
          "Copied": "Cкопировано",
          "Parent ID": "ID партнера",
          "Level": "Уровень",
          "LB Level": "Уровень лидерского бонуса",
          "Trees Amount": "Количество деревьев",
          "Deposit": "Депозит (личная покупка)",
          "1st Line Volume": "Оборот 1й линии",
          "2nd Line Volume": "Оборот 2й линии",
          "3rd Line Volume": "Оборот 3й линии",
          "Structure Volume": "Оборот структуры",
          "USDT Accrued": "USDT начислено",
          "Withdraw": "Вывести",
          "Buy Trees": "Купить деревья",
          "Amount to buy": "Количество",
          "Approve": "Подтвердить",
          "Buy": "Купить",
          "Tree ID": "ID Дерева",
          "Planting Year": "Год посадки",
          "Field": "Плантация",
          "Latitude": "Широта",
          "Longitude": "Долгота",
          "Buy Price": "Цена покупки",
          "Back": "Назад",
          "Total": "Всего",
          "Processing...": "В процессе...",
          "Price": "Цена",
          "Fall 2022": "Осень 2022",
          "Spring 2023": "Весна 2023",
          "Dear Eco Investors, welcome to user account.": "Уважаемые Эко инвесторы, добро пожаловать на страницу кабинета.",
          "To enter the account, registered users need to click on the Connect Wallet button in the upper right corner.": "Для входа в кабинет зарегистрированным пользователям необходимо нажать на кнопку Connect Wallet в правом верхнем углу.",
          "In order to register and purchase olive trees, you need to follow a few simple steps:": "Для того, чтобы зарегистрироваться и приобрести оливковые деревья, вам необходимо сделать несколько простых шагов:",
          "1. Install MetaMask wallet": "1. Установить кошелек MetaMask",
          "for": "для",
          "2. Deposit the required amount of USDT to your wallet": "2. Завести на кошелек необходимое количество USDT",
          "3. Purchase trees": "3. Приобрести деревья",
          "Open detailed step-by-step instructions": "Открыть подробную инструкцию",
          "Collapse": "Свернуть",
          "INSTRUCTIONS FOR BUYING OLIVES (ckeck the videos):": "ИНСТРУКЦИЯ ПОКУПКИ ОЛИВЫ (наглядно в видеороликах):",
          "1. CREATING A METAMASK WALLET": "1. СОЗДАНИЕ КОШЕЛЬКА METAMASK",
          "2. ACCESS TO THE USER'S ACCOUNT": "2. ДОСТУП К КАБИНЕТУ ПОЛЬЗОВАТЕЛЯ",
          "Detailed step-by-step instructions:": "Детальное описание тут",
          "1. Install the MetaMask application, create your account.": "1. Устанавливаем приложение MetaMask, создаём свой аккаунт.",
          "2. Switch MetaMask to the BNB Smart Chain network.": "2. Переключаем MetaMask на сеть BNB Smart Chain.",
          "3. Import the USDT token (it is needed to pay for the trees).": "3. Импортируем токен USDT (он необходим для оплаты деревьев).",
          "4. We replenish BNB for $3-5 to pay the transaction fee and transfer USDT to pay for trees at the rate of the cost of 1 tree in 2022 - $147, 2023 - $118. When depositing BNB and USDT to your MetaMask wallet, use the Binance Smart Chain (BEP20) network.": "4. Пополняем BNB на $3-5 для оплаты комиссии транзакции и пополняем USDT для оплаты деревьев из расчета стоимости 1 дерева 2022 года-$147, 2023 года-$118. При пополнении BNB и USDT на свой кошелёк MetaMask, используем сеть Binance Smart Chain (BEP20).",
          "5. Go to the MetaMask wallet menu and select the browser tab, insert a link to activate your personal account there.": "5. Заходим в меню кошелька MetaMask и выбираем вкладку браузер, там вставляем ссылку для активации личного кабинета.",
          "6. Request a link to activate the account from your broker. If you don't have a broker, write to us at": "6. Ссылку для активации кабинета запросите у своего брокера. Если у вас нет брокера, напишите нам в",
          "our support": "службу заботы",
          "and we will provide you with an expert to accompany your transactions.": "и мы предоставим вам эксперта для сопровождения ваших сделок.",
          "7. After gaining access, you will be taken to the page for activating your personal account. Click the Connect Wallet button.": "7. После получения доступа, вы попадаете на страницу активации личного кабинета. Нажимаете кнопку Connect Wallet.",
          "8. You have a mint NFT button. Click on the button and pay for the mint NFT transaction.": "8. У вас появляется кнопка mint NFT. Нажать на кнопку и оплатить транзакцию минта NFT.",
          "9. Next, click the Approve button at the bottom left and confirm the transaction.": "9. Далее нажимаете внизу слева кнопку Approve и подтверждаете транзакцию.",
          "10. Specify the number of trees you want to buy and click the Buy button. Next, you need to confirm the transaction.": "10. Укажите количество деревьев, которые хотите купить и нажмите кнопку Buy. Далее необходимо подтвердить транзакцию.",
          "11. The number of purchased trees appeared in your account. By clicking on the number of trees, you will see the unboxing of your NFT.": "11. У вас в кабинете появилось количество купленных деревьев. Нажав на цифру деревьев, вы увидите распаковку вашего NFT.",
          "There is all the data about the purchased trees: the number of trees, the cadastral number of the plantation, the geodata of each tree, the price and year of planting the tree.": "Там все данные о купленных деревьях: номер каждого дерева, кадастровой номер плантации, геоданные каждого дерева, цена и год посадки дерева.",
          "12. Now, when you log into your wallet, you will have access to your browser, and you can buy trees on your own at any time.": "12. Теперь заходя в свой кошелёк, в браузере у вас будет сохранен доступ, и вы сможете в любой момент самостоятельно покупать деревья.",
          "If you fail to complete these 12 steps, write to our": "Если у вас не получится пройти эти 12 шагов, напишите нам в",
          "support team": "службу поддержки",
          "and we will help you become an Eco investor.": "и мы поможем вам стать Эко инвестором."
        }
      }
    },
    fallbackLng: "en",
    debug: true,

    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, 

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
