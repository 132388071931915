import { useState, useEffect, useContext } from "react";
import { SessionContext } from "../../context";
import { State } from "../../Navigation";
import { getUserDataById_1 } from "../../contracts/Web3Client";
import { useTranslation } from "react-i18next";
import './ProductAmount.sass';

function DrawTreeAmount({ amount, children, handleClick }) {
    if (amount > 0) {
        return (
            <a href="#" onClick={handleClick}>{children}</a>
        );
    } else {
        return (<div>{children}</div>);
    }
}

const ProductsAmount = ({ products, isUpdateRequested }) => {

    const { t } = useTranslation();
    const { userToken, setViewState, setFilterProductId } = useContext(SessionContext);
    const [productsAmountArray, setProductsAmountArray] = useState([]);

    useEffect(() => {
        if (products !== undefined && products?.length > 0 && userToken > 0) {
            Promise.all(products.map(async (product) => {
                let data_1 = await getUserDataById_1(userToken, product.value);
                return {
                    id: product.value,
                    label: product.label,
                    amount: data_1.treesAmount
                };
            })).then(result => {
                setProductsAmountArray(result);
            });
        }
    }, [products, userToken, isUpdateRequested]);

    const treeAmountClick = (event, _productId) => {
        event.preventDefault();
        setFilterProductId(_productId);
        setViewState(State.ViewTrees);
    }

    return (
        <div className="trees-amount">
            {productsAmountArray?.length > 0 && productsAmountArray.map((product) => {
                return (
                    <div>
                        <span>{t("Trees Amount")} ({product.label}):</span>
                        <DrawTreeAmount amount={product.amount} handleClick={event => treeAmountClick(event, product.id)}>
                            <span className="accent-color">{product.amount}</span>
                        </DrawTreeAmount>
                    </div>
                )
            })}
        </div>
    )
}
export default ProductsAmount;