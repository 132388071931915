import { useTranslation } from "react-i18next";
import './PurchaseSection.sass';
import { useState, useContext, useEffect, useRef } from "react";
import { SessionContext } from "../../context";
import { getUserDataById_1, getUserDataById_2, getUserDataById_3, buy, allowance, approve, getPrice, getMinAmount, getMaxAmount } from "../../contracts/Web3Client"
import { State } from "../../Navigation";
import Radio from "../radio/Radio";
import ProductsAmount from "../productsAmount/ProductsAmount";

const denominator = 1000000000000000000.0;

const PurchaseSection = () => {
  const { t } = useTranslation();
  const { account, userToken, setIsBusy, viewState } = useContext(SessionContext);
  const [isApproved, setApproved] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [userDataById_1, setUserDataById_1] = useState();
  const [userDataById_2, setUserDataById_2] = useState(null);
  const [userDataById_3, setUserDataById_3] = useState(null);
  const [buyRequested, setBuyRequested] = useState(false);
  const [amountToBuy, setAmountToBuy] = useState(1n);
  const copyBlockInput = useRef(null);
  const [refUrl, setRefUrl] = useState("");
  const [approveRequested, setApproveRequested] = useState(false);
  const [pageUpdateRequested, setPageUpdateRequested] = useState(false);
  const [price, setPrice] = useState({ price: 0n, total: 0n });
  const [minAmount, setMinAmount] = useState(0n);
  const [maxAmount, setMaxAmount] = useState(1n);
  const [products, setProducts] = useState([]);
  const [productsBought, setProductsBought] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(1);

  useEffect(() => {
    setProductsBought([
      { value: 0, label: "2022" },
      { value: 1, label: "2023" }
    ]);
    setProducts([
      { value: 1, label: "2023" }
    ]);
  }, []);



  useEffect(()=> {
    const url = new URL(window.location.href);
    url.searchParams.set('id', userToken);
    setRefUrl(url.href);
  }, [userToken]);

  // init
  useEffect(() => {
    if (userToken <= 0 || account === undefined) return;
    allowance(account)
      .then((isAllowed) => {
        setApproved(isAllowed);
      });
    getUserDataById_1(userToken, selectedProduct)
      .then((data_1) => {
        setUserDataById_1(data_1);
        if (data_1?.level > 0) {
          getUserDataById_2(userToken)
            .then((data_2) => {
              setUserDataById_2(data_2);
            });
          getUserDataById_3(userToken)
            .then((data_3) => {
              setUserDataById_3(data_3);
            });
        }
      });
  }, [userToken, account, pageUpdateRequested]);

  useEffect(() => {
    async function getProductInfo() {
      if (userToken <= 0) return;
      getMinAmount(userToken, selectedProduct)
        .then((_minAmount) => {
          if (_minAmount != minAmount) {
            setMinAmount(_minAmount);
            setAmountToBuy(_minAmount);
          }

        });
      getMaxAmount(selectedProduct)
        .then((_maxAmount) => {
          setMaxAmount(_maxAmount);
        });
    }
    getProductInfo();
  }, [userToken, selectedProduct, pageUpdateRequested]);

  useEffect(() => {
    if (userToken <= 0 || amountToBuy <= 0) return;
    let currentAmountToBuy = amountToBuy;
    getPrice(userToken, selectedProduct, currentAmountToBuy)
      .then((_price) => {
        setPrice({
          price: _price,
          total: _price * currentAmountToBuy
        });
      });
  }, [userToken, amountToBuy, selectedProduct, pageUpdateRequested]);



  useEffect(() => {
    async function doBuy() {
      if (buyRequested === true) {
        await buy(account, selectedProduct, amountToBuy, price.price, minAmount, maxAmount);
        setPageUpdateRequested(!pageUpdateRequested);
      }
      setIsBusy(false);
      setBuyRequested(false);
    }
    doBuy();
  }, [buyRequested, selectedProduct]);

  const buyClick = async event => {
    event.preventDefault();
    if (!buyRequested) {
      setIsBusy(true);
      setBuyRequested(true);
    }
  };
  const amountToBuyOnChange = event => {
    const value = event.target.value.replace(/\D/g, "");
    setAmountToBuy(BigInt(value));
  };

  useEffect(() => {
    async function doApprove() {
      if (approveRequested) {
        setApproved(await approve(account));
      }
      setIsBusy(false);
      setApproveRequested(false);
    }
    doApprove();
  }, [approveRequested]);

  const copyClick = event => {
    event.preventDefault();
    copyBlockInput.current.select();
    document.execCommand("copy");
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
    }, 1500);
  }


  const approveClick = event => {
    event.preventDefault();
    if (!approveRequested) {
      setIsBusy(true);
      setApproveRequested(true);
    }
  };

  function formatCurrency(value) {
    if (typeof (value) == 'bigint') {
      return (value / BigInt(denominator)).toString();
    }
    if (value > 0) {
      return Math.floor(value / denominator);
    } else {
      return value;
    }
  }

  function formatPrice(value) {
    if (typeof (value) == 'bigint') {
      return (value / BigInt(denominator)).toString();
    }
    if (value > 0) {
      return value / denominator;
    } else {
      return value;
    }
  }

  return (
    <div className={"step-purchase " + (viewState !== State.Purchase && "block-hidden")}>
      <div className={"section " + (userDataById_1?.level <= 0 && "block-hidden")}>
        <div className="copy-block">
          <p>
            {t("You referral link")}: <span className="accent-color">{refUrl}</span>
            <a href="/" className="copy-icon" onClick={copyClick}>
              <img src="copy.svg" alt="copy" />
            </a>
            <input type="text" name="input-ref" value={refUrl} ref={copyBlockInput} />
            {isVisible && <span className="tooltip" >{t("Copied")}!</span>}
          </p>

        </div>
      </div>

      <div className="section">
        <div className="list-wrap">
          <div className="list">
            <div className="list-col">
              <div><span className={userDataById_1?.level <= 0 ? "mb-42" : ""}>{t("ID")}:</span> <span className="accent-color">{userDataById_1?.userTokenId ?? ""}</span> </div>
              <div className={"mb-42 " + (userDataById_1?.level <= 0 ? "block-hidden" : "")}><span>{t("Parent ID")}:</span> <span className="accent-color">{userDataById_1?.parentTokenId ?? ""}</span></div>
              <div style={{ margin: "52px" }}></div>
              <ProductsAmount products={productsBought} isUpdateRequested={pageUpdateRequested} />
            </div>
            <div className={"list-col " + (userDataById_2 === null ? "block-hidden" : "")}>
              <div className="mb-42"><span>{t("Deposit")}:</span> <span className="accent-color">${formatCurrency(userDataById_2?.personalDeposit ?? 0)}</span> </div>
              <div><span>{t("1st Line Volume")}:</span> <span className="accent-color">${formatCurrency(userDataById_2?.firstLineVolume ?? 0)}</span> </div>
              <div><span>{t("2nd Line Volume")}:</span> <span className="accent-color">${formatCurrency(userDataById_2?.secondLineVolume ?? 0)}</span> </div>
              <div className="mb-42"><span>{t("3rd Line Volume")}:</span> <span className="accent-color">${formatCurrency(userDataById_2?.thirdLineVolume ?? 0)}</span> </div>
              <div><span>{t("Structure Volume")}:</span> <span className="accent-color">${formatCurrency((userDataById_2?.firstLineVolume ?? 0) + (userDataById_2?.secondLineVolume ?? 0) + (userDataById_2?.thirdLineVolume ?? 0))}</span> </div>

            </div>
            <div className={"list-col " + (userDataById_3 === null ? "block-hidden" : "")}>
              <div style={{ margin: "101px" }}></div>
              <div><span>{t("USDT Accrued")}:</span> <span className="accent-color">{formatCurrency(userDataById_3?.USDBalanceAccrued ?? 0)}</span> </div>
            </div>

          </div>
        </div>
      </div>

      <div className="section">
        <h2>{t("Buy Trees")}</h2>
        <form action="#" method="POST">
          <Radio items={products} setSelectedItem={setSelectedProduct} defaultValue="1" />
          <div className="field-wrap">
            <label>{t("Amount to buy")}</label>
            <div className="input-wrap">
              <input type="text" name="amount" value={amountToBuy?.toString() ?? 0} required onChange={amountToBuyOnChange} />
              <span className="min-value">min: {minAmount?.toString() ?? 0}</span>
              <span className="max-value">max: {maxAmount?.toString() ?? 0}</span>
            </div>
            {!isApproved ? (<a href="/" className="btn btn-light" onClick={approveClick}>{t("Approve")}</a>) : (undefined)}
            <button className={"btn btn-theme " + (!isApproved ? "btn-disabled" : "")} onClick={buyClick} >{t("Buy")}</button>
          </div>

          <p>{t("Price")}: ${formatPrice(price.price)}</p>
          <p>{t("Total")}: ${formatPrice(price.total)}</p>

        </form>
      </div>
    </div>
  )
}

export default PurchaseSection;