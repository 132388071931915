import { useState, useEffect } from "react";
import newId from "./../../common/newid"
import './Radio.sass';

const Radio = ({items, setSelectedItem, defaultValue}) => {

    const [selectedValue, setSelectedValue] = useState(defaultValue);
    const [radioName, setRadioName] = useState("");

    useEffect(()=>{
        setRadioName(newId("radio-"));
    }, []);

    const handleChange = event => {
        setSelectedValue(event.target.value);
        setSelectedItem(event.target.value);
    };

    return (
        <div className="field-wrap radio-wrap">
            {items?.length > 0 ? items.map((option, index) => {
                return (
                    <div>
                        <input type="radio"
                            name={radioName}
                            id={radioName + index}
                            value={option.value}
                            checked={selectedValue == option.value}
                            onChange={handleChange} />
                        <label for={radioName + index}>{option.label}</label>
                    </div>
                )
            }): (undefined)}
        </div>
    )
}

export default Radio;