import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import './TreesDetails.sass';
import { SessionContext } from "../../context";
import { State } from "../../Navigation";
import { getTreesId, getTreeInfo } from "../../contracts/Web3Client";
import PaginatedTrees from "../paginatedTrees/PaginatedTrees";

const TreesDetails = ({productId}) => {
    const { t } = useTranslation();
    const { userToken, viewState, setViewState, setIsBusy } = useContext(SessionContext);
    const [trees, setTrees] = useState([]);


    useEffect(() => {
        async function doTreesDetails() {
            if (viewState === State.ViewTrees) {
                let treesIdArr = await getTreesId(userToken, productId);
                let _trees = await Promise.all(treesIdArr.map(async (treeId) => {
                    let treeInfo = await getTreeInfo(treeId);
                    if (treeInfo === null) {
                        return {
                            field: t("Processing..."),
                            plantingYear: t("Processing..."),
                            latitude: t("Processing..."),
                            longitude: t("Processing..."),
                            treeId: treeId
                        };
                    } else {
                        return { ...treeInfo, plantingYear: 2000 + Number(treeInfo.plantingYear), treeId: treeId };
                    }
                }));                
                setTrees(_trees);
            } else {
                setTrees([]);
            }
            setIsBusy(false);
        }
        doTreesDetails();
    }, [viewState]);

    const backClick = event => {
        event.preventDefault();
        setViewState(State.Purchase);
    }

    return (
        <div className={"trees-details " + (viewState !== State.ViewTrees && "block-hidden")}>
            <div className="details-header">
                <button onClick={backClick}>
                    <img src="back.svg" alt="back" />
                    {t("Back")}
                </button>
                <p>{t("Total")}: {trees.length}</p>
            </div>
            <PaginatedTrees itemsPerPage={50} trees={trees} />
        </div>
    )


}

export default TreesDetails;