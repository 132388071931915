import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import './MintSection.sass';
import { SessionContext } from "../../context";
import { mint, checkParentId } from "../../contracts/Web3Client";
import { getNextState, State } from "../../Navigation";

const MintSection = () => {
  const { t } = useTranslation();
  const { account, setUserToken, setIsBusy, viewState, setViewState } = useContext(SessionContext);
  
  const [parentTokenId, setParentTokenId] = useState(0);
  const [mintRequested, setMintRequested] = useState(false);
  const [isParentIdValid, setParentIdValid] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    let _parentTokenId = url.searchParams.get('id') ?? null;
    checkParentId(_parentTokenId).then((result) => {setParentIdValid(result); setParentTokenId(_parentTokenId)});
  }, [account]);

  useEffect(() => {
    async function doMint() {
      if (viewState == State.NotMinted && mintRequested === true) {
        const token = await mint(account, parentTokenId);
        setUserToken(token);
        setViewState(getNextState(account, token));
      }
      setIsBusy(false);
      setMintRequested(false);
    }
    doMint();
  }, [mintRequested, viewState]);

  const mintClick = async event => {
    event.preventDefault();
    if (!mintRequested) {
      setIsBusy(true);
      setMintRequested(true);
    }
  };

  return (
    <div className={"step-mint " + (viewState !== State.NotMinted && "block-hidden")}>
      <form action="#" method="POST" onSubmit={mintClick}>
        <div className="field-wrap">
          <p>{t("You should mint NFT to join BioDeposit Smart.")}</p>
          <p className={"error-color " + (isParentIdValid && "block-hidden")}>{t("To mint NFT please use a link provided by your broker. If you don't have such link please reach our")} <a href="https://t.me/biodeposit_support_bot" target="_blank">{t("support.")}</a></p>
          <button type="submit" className={"btn btn-theme " + (!isParentIdValid && "btn-disabled")}>{t("Mint")}</button>
        </div>
      </form>
    </div>
  )
}

export default MintSection;